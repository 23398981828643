import {createContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {MainTitle} from '../../../../commons/components/Title/MainTitle';
import {toast} from 'react-toastify';
import DndSection from '../../../../commons/components/dndForms/DndForms';
import {ModalErrors} from '../../../../commons/components/modals/ModalErrors';
import {useSelector} from 'react-redux';
import {useMutation, useQueryClient, useQuery} from 'react-query';
import {useErrorHandling} from '../../../../commons/hooks/useErrorHandling';
import {IconCheckCircle, IconPencil} from '../../../../commons/icons';
import {
  IEventActivityForm,
  IEventActivity,
  IEventActivityPrepared,
} from '../../../../commons/interfaces/event/eventSchedule.interface';
import eventScheduleService from '../../../../services/event/eventScheduleService';
import {RootState} from '../../../../store/store';
import {defaultEventActivityForm, activityFormRules} from '../config/EventScheduleConfig';
import eventService from '../../../../services/event';

interface eventDates {
  start_date: string
  end_date: string
};

// The createContext hook is used to create a context to pass the event data to the DndSection component.
export const eventContext = createContext<eventDates>({start_date: '', end_date: ''});

/*
 * EventSchedulePage
 * This component is responsible for rendering the page of schedule of the event.
 * It uses the DndSection component to render the form with the schedule.
 * It uses the useQuery, useMutation and useQueryClient hooks from react-query to make the requests to the API.
 * It uses the useSelector hook from react-redux to access the userLogin state.
 * It uses the useErrorHandling hook to handle the errors.
 */
const EventSchedulePage = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const {userLogin} = useSelector((state: RootState) => state.auth);
  const {errorType, handleApiError, clearError} = useErrorHandling();
  const [activities, setActivities] = useState<IEventActivityPrepared[]|null>(null);
  const [defaultFormValues, setDefaultFormValues] = useState<IEventActivityPrepared>(defaultEventActivityForm);
  // The useParams hook returns an object with the params of the URL.
  // In this case, we are destructuring the id from the params object.
  const {id} = params;
  // The useState hook is used to manage the formDisabled state.
  const [formDisabled, setFormDisabled] = useState(true);
  // The useQuery hook is used to make a request to the API to get the event schedule.
  const {
    data: event,
    isLoading: isLoadingEvent,
    isError: isErrorEvent,
  } = useQuery(['eventView', id], () => eventService.show(+id!));
  // The useQuery hook returns an object with the data, isLoading and isError properties.
  const {
    data: eventSchedule,
    isLoading,
    isError,
    refetch
  } = useQuery(['eventScheduleView', id], () => eventScheduleService.show(+id!));
  // The useMutation hook returns an object with the mutation function and the status of the mutation.
  const userMutation = useMutation({
    // The mutationFn is the function that makes the request to the API.
    mutationFn: eventScheduleService.upsert,
    // The onSuccess function is called when the mutation is successful.
    // It shows a success toast and navigates to the event schedule page.
    // It also invalidates the query to update the data.
    onSuccess: () => {
      toast.success('Los cambios fueron guardados con éxito.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      queryClient.invalidateQueries('eventScheduleView',{ refetchActive: true })
      refetch()
      setFormDisabled(true)
    },
    // The onError function is called when the mutation fails.
    onError: (e: any) => {
      handleApiError(e)
    },
  });
  // The onSubmit function is called when the form is submitted.
  const onSubmit = (elements: IEventActivityForm[]) => {
    const data = {
      content_event_id: Number(id),
      content_event_schedule: elements.map((element) => {
        const {code, cardTitle, date_init, date_end, hour_init, hour_end, ...rest} = element;
        const activity: IEventActivity = {
          ...rest,
          date_init: date_init + ' ' + hour_init,
          date_end: date_end + ' ' + hour_end,
        };
        return activity;
      }),
    }
    userMutation.mutate(data);
  };
  // Function to trasnform date to time.
  const getTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (eventSchedule?.data?.result) {
      const newActivities: IEventActivityPrepared[] = [];
      eventSchedule?.data?.result.forEach((activity) => {
        newActivities.push({
          id: activity.id,
          name: activity.name,
          content: activity.content,
          management_file_public: activity.management_file_public,
          date_init: activity?.date_init?.split('T')[0],
          date_end: activity?.date_end?.split('T')[0],
          hour_init: getTime(activity.date_init),
          hour_end: getTime(activity.date_end),
        });
      });
      setActivities(newActivities);
    }
  }, [eventSchedule]);

  useEffect(() => {
    if (event?.data?.result) {
      setDefaultFormValues({
        ...defaultEventActivityForm,
        date_init: event.data.result.start_date,
        date_end: event.data.result.end_date,
      });
    }
  }, [event]);


  // The isLoading and isError properties are used to show a loading message or an error message.
  if (isLoading || isLoadingEvent) {
    return <div>Cargando...</div>
  };
  // The isError property is used to show an error message.
  if (isError) {
    return <div>Error al cargar las actividades</div>
  };
  if (isErrorEvent) {
    return <div>Error al cargar el evento</div>
  };
  
 
  return (
    <>
      <div className='d-flex justify-content-between flex-column'>
        <div className='d-flex justify-content-between align-items-center mb-6'>
          <MainTitle disabled={formDisabled} title='Agenda' editTitle='Agenda' icon
          />
          {/* The button is used to enable the form to edit the activities of the schedule. */}
          {formDisabled &&
            eventSchedule?.data?.result.length !== 0 &&
            (userLogin?.capability_permissions.includes('content_event_update') ||
              userLogin?.admin === 1) && (
              <button
                onClick={() => setFormDisabled(false)}
                type='button'
                className='btn btn-primary btn-outline-primary me-2 d-flex align-items-center gap-3'
              >
                <IconPencil />
                Editar agenda
              </button>
            )}
        </div>
        {/* The DndSection component is used to render the form with the activities. */}
        {activities && event?.data?.result && (
          <eventContext.Provider
            value={{start_date: event.data.result.start_date, end_date: event.data.result.end_date}}
          >
            <DndSection
              defaultForm={defaultFormValues}
              formRules={activityFormRules}
              initialForms={activities}
              formName='activity'
              formDisabled={formDisabled && activities.length !== 0}
              prefixCode='Actividad'
              handleFunction={onSubmit}
              addButtonText='Añadir actividad'
            />
          </eventContext.Provider>
        )}
      </div>
      {/* The ModalErrors component is used to show an error message. */}
      {errorType && <ModalErrors errorType={errorType} onClose={clearError} />}
    </>
  )
}

export default EventSchedulePage;
